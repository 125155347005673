import { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
// import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg'
// import { ReactComponent as Brand } from '../../assets/icons/logo.svg'
import './NavBar.css'
import { TiThMenu } from "react-icons/ti";
import { HashLink } from 'react-router-hash-link';
import { AppContext } from '../../contexts/AppContext';
import { FaInstagram } from 'react-icons/fa6';

const NavBar = () => {
    const [showNavbar, setShowNavbar] = useState(false)
    const { setShowPopup } = useContext(AppContext)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    return (
        <nav className="navbar">
            <div className="navContainer">
                <div className="logo">
                    <NavLink to="/" style={{ color: 'white' }}>
                        <p className='logoText'>Royz</p>
                    </NavLink>
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <TiThMenu size={'25px'} />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>

                        <li>
                            <HashLink to="/#our-fleet" smooth>Our Cars</HashLink>
                        </li>
                        <li>
                            <HashLink to="/#reviews" smooth>Reviews</HashLink>
                        </li>
                        <li>
                            <div style={{ cursor: 'pointer' }} onClick={() => setShowPopup(true)}>Contact</div >
                        </li>
                        <li>
                            <HashLink to="/#FAQ" smooth>FAQ</HashLink>
                        </li>
                        <li>
                            <NavLink target="_blank" to="https://www.instagram.com/royzrental/"><FaInstagram size={22} /></NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            {/* <div className='book-private-banner'>
                Save money and book privately!
            </div> */}
        </nav>
    )
}

export default NavBar