import React, { createContext, useEffect, useState } from 'react';
import { fetchTSVData } from '../lib/smallFunctions';


export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [showPopup, setShowPopup] = useState(null);
    const [sheetsCarData, setSheetsCarData] = useState([])
    const [loadingSheetsData, setLoadingSheetsData] = useState(true)
    const [showCarDetails, setShowCarDetails] = useState(false)
    const [chosenCarIndex, setChosenCarIndex] = useState(null)
    const [categoryFilter, setCategoryFilter] = useState('all');

    const handleClickCar = (index) => {
        setChosenCarIndex(index)
        setShowCarDetails(true)
        // if (!index) setChosenCarIndex(false)
    }

    useEffect(() => {
        setLoadingSheetsData(true)
        fetchTSVData(setSheetsCarData)
        setLoadingSheetsData(false)
    }, [])


    return (
        <AppContext.Provider
            value={{
                showPopup, setShowPopup,
                showCarDetails, setShowCarDetails,
                chosenCarIndex, setChosenCarIndex,
                handleClickCar,
                sheetsCarData, setSheetsCarData,
                loadingSheetsData, setLoadingSheetsData,
                categoryFilter, setCategoryFilter
            }}>
            {children}
        </AppContext.Provider>
    );
};