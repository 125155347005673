import React, { useRef, useContext } from 'react';
import './CarList.css';
import { Slide } from 'react-awesome-reveal';
import { SiLandrover, SiNissan, SiMercedes, SiVauxhall, SiAudi, SiBmw, SiJaguar } from "react-icons/si";
import { our_fleet } from '../../data/OurFleets';
import IndividualCarCard from '../smallComponents/IndividualCarCard/IndividualCarCard';
import { AppContext } from '../../contexts/AppContext';

function CarList() {
    const { categoryFilter, setCategoryFilter } = useContext(AppContext)
    // const [carsShown, setCarsShown] = useState(false);
    const categoryContainerRef = useRef(null);

    const car_categories = [
        {
            name: 'BMW',
            icon: <SiBmw className='category-icon' />
        },
        {
            name: 'Audi',
            icon: <SiAudi className='category-icon' id='icon-bigger' />
        },
        {
            name: 'Land Rover',
            icon: <SiLandrover className='category-icon' />
        },
        {
            name: 'Mercedes-Benz',
            icon: <SiMercedes className='category-icon' />
        },
        {
            name: 'Vauxhall',
            icon: <SiVauxhall className='category-icon' />
        },
        {
            name: 'Nissan',
            icon: <SiNissan className='category-icon' />
        },
        {
            name: 'Jaguar',
            icon: <SiJaguar className='category-icon' />
        }
    ];

    const handleCategoryClick = (category) => {
        setCategoryFilter(category);
        handleScrollToCategories()
    };

    const handleScrollToCategories = () => {
        if (categoryContainerRef.current) {
            categoryContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <div id='our-fleet'>
            <Slide direction='down' duration={1500} triggerOnce>
                <div className='category-container'>
                    <div
                        ref={categoryContainerRef}
                        className={categoryFilter === "all" ? 'category-box-selected' : 'category-box'}
                        onClick={() => handleCategoryClick("all")}
                        id='all-category'
                    >
                        {/* <h3 style={{ letterSpacing: 5 }}>ALL</h3> */}
                        {/* <MdGarage className='category-icon' /> */}
                        <div className='all-cars-png'>
                            <img src={require('../../assets/small_assets/all-cars.png')} alt="all cars" style={{ width: '65%' }} />
                        </div>
                        all
                        <div className={categoryFilter === "all" ? 'number-of-cars-selected' : 'number-of-cars'}>
                            {our_fleet.length}
                        </div>
                    </div>
                    {car_categories.map((category, index) =>
                        <div
                            key={index}
                            className={categoryFilter === category.name ? 'category-box-selected' : 'category-box'}
                            onClick={() => handleCategoryClick(category.name)}
                        >
                            {category.icon}
                            {category.name}
                            <div className={categoryFilter === category.name ? 'number-of-cars-selected' : 'number-of-cars'}>
                                {our_fleet.filter((item) => item.make === category.name).length}
                            </div>
                        </div>
                    )}
                </div>
            </Slide>
            <Slide direction='up' duration={1500} triggerOnce>
                <div className='all-cars-container'>
                    {our_fleet?.filter((item) => categoryFilter !== 'all' ? item.make === categoryFilter : item).length === 0 ? (
                        <div className='no-results'>No cars from this brand at the moment but will be coming soon!</div>
                    ) : (
                        our_fleet?.filter((item) => categoryFilter !== 'all' ? item.make === categoryFilter : item).map((car, index) =>
                            <IndividualCarCard key={car.registration_number} item={car} index={index} />
                        )
                    )}
                </div>
            </Slide>
            <div style={{ height: 90 }}></div>
            {/* {carsShown &&
                <div className='scroll-to-cat-button' onClick={() => handleScrollToCategories()}>
                    <HiChevronDoubleUp />
                </div>
            } */}
        </div>
    );
}

export default CarList;
