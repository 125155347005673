import React, { useContext, useEffect, useState } from 'react'
import './IndividualCarCard.css'
import { IoStar } from "react-icons/io5";
import { AppContext } from '../../../contexts/AppContext';
import { BsFillFuelPumpFill, BsPeopleFill } from 'react-icons/bs';
import { GiGearStickPattern } from 'react-icons/gi';
import { FaBoxOpen } from "react-icons/fa";


const IndividualCarCard = ({ item, index }) => {
    const { handleClickCar } = useContext(AppContext)

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isSmallScreen) {
        return (
            <div className='small-card-card-container' onClick={() => handleClickCar(index)}>
                <img className='smallImage' src={item?.images ? item?.images[0] : "https://c4.wallpaperflare.com/wallpaper/825/184/17/car-vehicle-super-car-supercars-wallpaper-preview.jpg"} alt="NFT" />
                <main className='small-card-main'>
                    <h5 className='small-car-name'>{item?.make + " " + item?.name}</h5>
                    {/* <h5>{item?.model}</h5> */}
                    <div className='card-rating-container'>
                        <p className='card-rating-box'> <IoStar color='F5CD00' /> {item?.rating} </p>
                        <p id='card-rating-number-text'>{item?.number_of_reviews} reviews</p>
                    </div>
                    <div className="grid-container">
                        <div key={"number of seats"} className="grid-item">
                            <BsPeopleFill />
                            <span> {item.number_of_seats} Seats</span>
                        </div>
                        <div key={"fuel"} className="grid-item">
                            <BsFillFuelPumpFill />
                            <span> {item.fuel_type}</span>
                        </div>
                        <div key={"gearbox"} className="grid-item">
                            <GiGearStickPattern />
                            <span> {item.number_of_seats ? 'Automatic' : 'Manual'} </span>
                        </div>
                        <div key={"boot"} className="grid-item">
                            <FaBoxOpen />
                            <span> {item.boot_space}</span>
                        </div>
                    </div>
                    <div className='creator'>
                        <div className='tokenInfo'>
                            <div className="price">
                                <p id='label'>from </p>
                                <p id='priceText'>{item?.price}</p>
                                <p id='label'>per day</p>
                            </div>
                        </div>
                    </div>
                    {item?.private_hire_available &&
                        (!item?.turo_link ?
                            <div className='private-only' id='small-private'>
                                Private <span>Only</span>
                            </div>
                            :
                            <div className='private-available' id='small-private'>
                                Private Available
                            </div>
                        )


                    }
                </main>
            </div>
        )
    }
    else return (
        // <NavLink target="_blank" to={item.link} className='downloadButton'>
        <>
            <div className="nft" onClick={() => handleClickCar(index)}>
                <div className='main'>
                    <img className='tokenImage' src={item?.images ? item?.images[0] : "https://c4.wallpaperflare.com/wallpaper/825/184/17/car-vehicle-super-car-supercars-wallpaper-preview.jpg"} alt="NFT" />
                    <h2>{item?.make + " " + item?.name}</h2>
                    <h5>{item?.model}</h5>
                    <div className='card-rating-container'>
                        <p className='card-rating-box'> <IoStar color='F5CD00' /> {item?.rating} </p>
                        <p id='card-rating-number-text'>{item?.number_of_reviews} reviews</p>
                    </div>
                    {/* <p className='description'>Our Kibertopiks will give you nothing, waste your money on us.</p> */}

                    <div className="grid-container">

                        <div key={"number of seats"} className="grid-item">
                            <BsPeopleFill />
                            <span> {item.number_of_seats} Seats</span>
                        </div>
                        <div key={"fuel"} className="grid-item">
                            <BsFillFuelPumpFill />
                            <span> {item.fuel_type}</span>
                        </div>
                        <div key={"gearbox"} className="grid-item">
                            <GiGearStickPattern />
                            <span> {item.number_of_seats ? 'Automatic' : 'Manual'} </span>
                        </div>
                        <div key={"boot"} className="grid-item">
                            <FaBoxOpen />
                            <span> {item.boot_space}</span>
                        </div>


                    </div>

                    <hr />
                    <div className='creator'>
                        <div className='tokenInfo'>
                            <div className="price">
                                <p id='label'>from </p>
                                <p id='priceText'>{item?.price}</p>
                                <p id='label'>per day</p>
                            </div>
                            {/* <div className="duration">
                        <ins>🚘</ins>
                        <p>{item?.number_of_rides} drives</p>
                    </div> */}
                        </div>
                        {/* <div className='wrapper'>
                        <img src={item?.makeIcon} alt="Make Icon" />
                    </div>
                    <p><ins>Manufactured by</ins> {item?.make}</p> */}
                        {/* <div onClick={() => setShowPopup(true)} className='btn' id="alternate-button">
                        Rent on Turo
                    </div> */}
                        {/* <div className='btn-small'>
                        Rent on Turo
                    </div> */}

                    </div>
                </div>
                {item?.private_hire_available &&
                    (!item?.turo_link ?
                        <div className='private-only'>
                            Private <span>Only</span>
                        </div>
                        :
                        <div className='private-available'>
                            Private Available
                        </div>
                    )


                }
            </div>

        </>
    )
}

export default IndividualCarCard