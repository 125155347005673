import React, { useContext } from 'react'
import './MapTile.css'
import {
    APIProvider,
    Map,
    AdvancedMarker,
} from '@vis.gl/react-google-maps'
import { AppContext } from '../../contexts/AppContext';
import { our_fleet } from '../../data/OurFleets';


export default function MapTile() {
    const { handleClickCar } = useContext(AppContext)

    // custom cluster icon
    // const createClusterCustomIcon = function (cluster) {
    //     return new divIcon({
    //         html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    //         className: "custom-marker-cluster",
    //         iconSize: point(33, 33, true)
    //     });
    // };

    return (
        <div className='car-locations-container'>
            <h3 className='small-title'>Car Locations</h3>
            <div className='description'>Our cars can be found in great spots around central London, especially around travel hubs such as Kings Cross St.Pancras, Tottenham Court Road and Euston, making travel so much easier from wherever you journey starts and ends.</div>
            <APIProvider apiKey='AIzaSyCoBZZw7HyzA_WkF2x0ykyDM0IJOSYMkSU' >
                <div
                    className='map-tile-container'
                >
                    <Map
                        defaultCenter={{ lat: 51.525550, lng: -0.132890 }}
                        defaultZoom={11.5}
                        mapId={"de697a2587a4d8a7"}
                        style={{ borderRadius: '0.75rem' }}
                    >
                        {our_fleet.map((marker, index) => (
                            <AdvancedMarker key={marker.name + index} zIndex={2} position={{ lat: Number(marker.geo_location_lat) ?? 0, lng: Number(marker.geo_location_lng) ?? 0 }} onClick={() => handleClickCar(index)}>
                                <img className='custom-icon' src={marker.images[0]} alt='car-icon' />
                            </AdvancedMarker>
                        ))}

                    </Map>
                </div >
            </APIProvider>
        </div>
    )
}
