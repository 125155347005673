import './App.css';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/NavBar';
import RouteHandler from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './contexts/AppContext';

function App() {

  return (
    <BrowserRouter>
      <Analytics />
      <SpeedInsights />
      <AppProvider>
        <div className='FullScreen'>
          <Navbar />
          <RouteHandler />
          <Footer />
        </div>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
