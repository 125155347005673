import React, { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/react-splide/css/core';
import { TestimonialSlide } from './TestimonialSlide';
import './Testimonial.css';

const Testimonial = () => {
    const [startSlide, setStartSlide] = useState(0);
    const [visibleReviews, setVisibleReviews] = useState(calculateVisibleReviews());
    const [hoveringOnCard, setHoveringOnCard] = useState(false)
    const splideRef = useRef(null);  // Reference to the Splide instance
    const isDraggingRef = useRef(false);  // Reference to track dragging state



    const testimonialData = [
        {
            name: 'Mike',
            image: 'https://images.turo.com/media/driver/03l_V3xoTDaiTDSEVaZKvw.220x220.jpg',
            rating: 5,
            date: new Date('05/14/2024'),
            reviewText: `Great car! Amazing and easy pick up and very fast response! Would definitely recommend!`,
            trips: 4,
            link: 'https://turo.com/gb/en/drivers/35232384',
        },
        {
            name: 'Ruth Mair H.',
            image: 'https://images.turo.com/media/driver/5fO8zbfpQa2ZsO9XeZwKDw.220x220.jpg',
            rating: 5,
            date: new Date('03/18/2024'),
            reviewText: `Clean, easy drive, easy to find
        Helpful owner
        See you again thanks`,
            trips: 1,
            link: 'https://turo.com/gb/en/drivers/41161204',
        },

        {
            name: 'Anirudh S.',
            image: 'https://images.turo.com/media/driver/Jb7dmyJmQvGlTUhXAZgtvw.220x220.jpg',
            rating: 5,
            date: new Date('03/08/2024'),
            reviewText: "Amazing host, very clean car and best of all super convenient automated pickup and drop.",
            trips: 3,
            link: null,
        },
        {
            name: 'Martin R',
            image: 'https://images.turo.com/media/driver/DbslSX2NRu2WdTsKqlsqPA.220x220.jpg',
            rating: 5,
            date: new Date('02/26/2024'),
            reviewText: `Fast and good communication to Shuhebur before and during the rental. Very clean and attractive BMW — with a bunch of onboard assistance systems.`,
            trips: 1,
            link: 'https://turo.com/gb/en/drivers/29796230',
        },
        {
            name: 'Leonard D',
            image: 'https://images.turo.com/media/driver/8ka6xS0yTyWBU2fhobqZYQ.220x220.jpg',
            rating: 5,
            date: new Date('02/29/2024'),
            reviewText: `The car was great .

        Great check in/out experience.
        
        Host was helpful and answered all my questions regarding the check in process.
        
        I would 100% rent the car again`,
            trips: 2,
            link: 'https://turo.com/gb/en/drivers/40592760',
        },
        {
            name: 'Ruhel I.',
            image: 'https://images.turo.com/media/driver/LEn_CCNCSbajjreLqY4myA.220x220.jpg',
            rating: 5,
            date: new Date('02/22/2024'),
            reviewText: "Been a pleasure to drive this car. Easy to contact Sami. Picking up and dropping off is easy. Had no problems throughout my whole journey. Definitely would rent again.",
            trips: 2,
            link: 'https://turo.com/gb/en/drivers/31470178',
        },
        {
            name: 'Nicholas O.',
            image: 'https://images.turo.com/media/driver/QRvwamCBRi-DQG91x6LC1g.220x220.jpg',
            rating: 5,
            date: new Date('02/20/2024'),
            reviewText: "Easy to find car, was fun to drive as well. Sami was extremely responsive and flexible. Might be a bit difficult to find free parking around Covent Garden on the return but just have to be a little bit patient.",
            trips: 1,
            link: 'https://turo.com/gb/en/drivers/40297039',
        },
        {
            name: 'Florjan P.',
            image: 'https://images.turo.com/media/driver/baBShGieTi2_VXQd_jBxDQ.220x220.jpg',
            rating: 5,
            date: new Date('04/27/2024'),
            reviewText: "Amazing host, helpful, and provided his phone number where I can contact him in case anything is needed. Finding the car was easy. Very well maintained car, very clean. The car was just amazing, fuel very efficient. Had absolutely no issues at all with the car. Will 100% book again. 🥇",
            trips: 8,
            link: 'https://turo.com/gb/en/drivers/15863774',
        },
        {
            name: 'Simon C.',
            image: 'https://images.turo.com/media/driver/hvjadFYXRnu33-B31GS_3Q.220x220.jpg',
            rating: 5,
            date: new Date('04/27/2024'),
            reviewText: "Superb car (didn’t want to give it back lol) Fantastic host too. Kept me in the loop the whole way. Would defo rent again. Thanks",
            trips: 6,
            link: 'https://turo.com/gb/en/drivers/12191765',
        },

        {
            name: 'Blair',
            image: 'https://images.turo.com/media/driver/obB_qNgfRVuj0oTpBzW6xA.220x220.jpg',
            rating: 5,
            date: new Date('04/21/2024'),
            reviewText: "The car was perfect for our trip to the English countryside for a few days. We are a family of 3 and everyone was very comfortable, and the trunk had enough room for all our bags. It was clean and easy to find. Shuhebur was very responsive to my messages. Overall, an excellent experience for our first Turo trip! Thanks for making it so great.",
            trips: 1,
            link: 'https://turo.com/gb/en/drivers/41746223',
        },
    ]

    useEffect(() => {
        const handleResize = () => {
            setVisibleReviews(calculateVisibleReviews());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setStartSlide((prevStart) => (prevStart + 1) % testimonialData.length);
        }, 5000); // Animation duration

        return () => clearInterval(interval);
    }, [startSlide, testimonialData.length]);


    function calculateVisibleReviews() {
        const viewportWidth = window.innerWidth;
        if (viewportWidth < 800) {
            return 1;
        } else if (viewportWidth < 1500) {
            return 2;
        } else {
            return 3;
        }
    }

    const handleMouseEnter = () => {
        if (!isDraggingRef.current && splideRef.current) {
            setHoveringOnCard(true)
        }
    };

    const handleMouseLeave = () => {
        if (splideRef.current) {
            setHoveringOnCard(false)
        }
    };

    const handleMouseDown = () => {
        isDraggingRef.current = true;
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
    };

    return (
        <div className='testimonial-container' id='reviews'>
            <div className='testimonial-heading-container'>
                <div className='sub-heading'>what Guests say</div>
                <h3 className='small-title' style={{ alignSelf: 'center' }}>Testimonials</h3>
            </div>
            <div>
                <div className='testimonialWrapper'>
                    <Splide
                        hasTrack={true}
                        options={{
                            perPage: visibleReviews,
                            arrows: false,
                            pagination: false,
                            drag: 'free',
                            gap: '3rem',
                            snap: true,
                            autoplay: !hoveringOnCard,
                            interval: 12000,
                            speed: 1300,
                            resetProgress: true,
                            type: 'loop',
                            autoHeight: true
                        }}
                    >
                        {testimonialData.map((item, index) => {
                            return (
                                <SplideSlide
                                    key={"testimonial" + index}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                >
                                    <TestimonialSlide item={item} />
                                </SplideSlide>
                            );
                        })}

                    </Splide>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;



