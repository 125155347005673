import React, { useContext } from 'react'
import './Home.css'
import Landing from '../../components/landing/Landing'
import HowItWorks from '../../components/howItWorks/HowItWorks'
// import AdsComponent from '../../components/ads/AdsComponent'
import Testimonial from '../../components/smallComponents/Testimonial/Testimonial'
import FrequentlyAsked from '../../components/faq/FrequentlyAsked'
import { Slide } from 'react-awesome-reveal'
import WhyChooseUs from '../../components/chooseUs/WhyChooseUs'
import { AppContext } from '../../contexts/AppContext'
import { ContactModal } from '../../components/modals/ContactModal'
import { CgShapeRhombus } from "react-icons/cg";
import MapTile from '../../components/map/MapTile'
import { CarDetailsModal } from '../../components/modals/carDetails/CarDetailsModal'
import CarList from '../../components/carList/CarList'

const Home = () => {
    const { showPopup, setShowPopup, showCarDetails, setShowCarDetails } = useContext(AppContext)

    return (
        <div className='home-container'>
            <CarDetailsModal isOpen={showCarDetails} setIsOpen={setShowCarDetails} />
            <ContactModal isOpen={showPopup} setIsOpen={setShowPopup} />
            <div className='first-container'>
                <CgShapeRhombus className='stencil' />
                <Landing />
                <CarList />


            </div>
            {/* <Slide direction='right' duration={1500} triggerOnce>
                <InfiniteTags />
            </Slide> */}
            <div className='why-choose-us-background'>
                <div className="custom-shape-divider-bottom-1709119856">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </div>

                <Slide direction='left' duration={1500} triggerOnce>
                    <WhyChooseUs />
                </Slide>
                <Slide direction='right' duration={1500} triggerOnce>
                    <MapTile />
                </Slide>


            </div>
            <div className='car-carousel-and-faq-background'>
                <div className="custom-shape-divider-bottom-1709212723">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                    </svg>
                </div>
                {/* <CarCarousel /> */}
                <Slide direction='up' duration={1500} triggerOnce>
                    <HowItWorks />
                </Slide>


                <Slide direction='left' duration={1500} triggerOnce>
                    <FrequentlyAsked />
                </Slide>
                <Slide direction='up' duration={1500} triggerOnce>
                    <Testimonial />
                </Slide>
            </div>
            {/* <div>
                <About />
            </div> */}
        </div>
    )
}

export default Home