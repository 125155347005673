import React from 'react'
import { NavLink } from 'react-router-dom';
import './Landing.css'
import { Slide } from 'react-awesome-reveal';
import carImage from '../../assets/car_images/Audi-Q8-cut-out.png'
import { HashLink } from 'react-router-hash-link';

const Landing = () => {
    return (
        <div className='LandingContainer'>

            <div className='LandingLeft' id='download'>
                <Slide direction='left' duration={1200} triggerOnce>
                    <div className="landing-content">
                        {/* <img src={logo} alt="big car" style={{ aspectRatio: 1, maxHeight: '16rem', height: '20vw' }} /> */}
                        <h1>Quick, Easy & Central Car Rental</h1>
                        <h3 className='landing-content-h3'>Unlock Your <span>Perfect</span> Car, Today</h3>
                        <div className='button-container'>
                            <NavLink target="_blank" to={'https://turo.com/gb/en/drivers/32411254'} className='downloadButton'>
                                <div className="btn" >Book now</div>
                            </NavLink>
                            <HashLink to="/#how-it-works" smooth>
                                <div href="#" className="btn" id='alternate-button'>How it Works</div>

                            </HashLink>
                        </div>

                    </div>
                </Slide>
            </div>

            <div className='LandingRight'>
                {/* <NavLink to="/features"> */}
                {/* <img alt='Never Be Lost on Where to Eat Again'  src={require('../../assets/Feature Graphic BIG-min.jpg')} /> */}
                <Slide direction='down' duration={1200} triggerOnce >
                    <div className="home-img">
                        <div className="rhombus">
                            <img src={carImage} alt="big car" style={{ height: '26vw', minHeight: '22vw' }} />
                        </div>
                    </div>
                </Slide>

                {/* <!-- Additional decorative element --> */}
                <div className="rhombus2"></div>
            </div>
            <div className='LandingRightSmall'>
                <Slide direction='right' duration={1200} triggerOnce >
                    <img alt='Feature Graphic' style={{ width: '100%' }} src={carImage} />
                </Slide>
            </div>
        </div>
    )
}

export default Landing